<template>
  <div id="component">
    <div class="info">

      <p>绿色供应链评价</p>
      <p><b>绿色供应链管理战略：</b>纳入公司发展规划、制定绿色供应链管理目标、设置专门管理机构</p>
      <p><b>实施绿色供应商管理：</b>绿色采购标准制度完善、供应商认证体系完善、对供应商定期审核、供应商绩效评估制度健全、定期对供应商进行培训、低风险供应商占比</p>
      <p><b>绿色生产：</b>节能减排环保合规、符合有害物质限制使用管理办法</p>
      <p><b>绿色回收：</b>产品回收率、包装回收率、回收体系完善（含自建、与第三方联合回收)、指导下游企业回收拆解</p>
      <p><b>绿色信息平台建设：</b>绿色供应链管理信息平台完善</p>
      <p><b>绿色信息披露：</b>披露企业节能减排减碳信息、披露高、中风险供应商审核率及低风险供应商占比、披露供应商节能减排信息、发布企业社会责任报告（含绿色采购信息)</p>
    </div>
    <div class="flowInfo">
      <p>申报流程︰</p>
      <p>（1）省工信厅发布通知组织申报;</p>
      <p>（2）企业报名;</p>
      <p>（3）地方工信主管部门筛选推荐;</p>
      <p>（4）获得推荐的企业根据《绿色供应链管理评价要求》要求进行自评价,编写自评价报告;</p>
      <p>（5）企业委托第三方机构进行现场评价,第三方对自评价报告按照《绿色供应链管理评价要求》的指标体系进行评分,并出具第三方评价报告;</p>
      <p>（6）企业将相关资料提交到地方工信主管部门,地方递交至省级工信主管部门,由省级工信主管部门进行评估;</p>
      <p>（7）省级工信主管部门将推荐名单报送至工信部﹔</p>
      <p>（8）工信部组织专家论证并进行公示,确定绿色供应链管理示范企业名单。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "GreenSupplyChain"
}
</script>

<style scoped>
.info{
  font-size: 20px;
  line-height: 40px;
}
.flowInfo{
  margin: 40px auto;
  font-size: 20px;
  line-height: 40px;
}
</style>