<template>
  <div id="main">
    <h2>①服务内容</h2>
    <p class="content" style="text-align: left">
      协助企业完成绿色设计产品申报，企业产品获得“绿色设计产品”标识，企业纳入到政府采购首购名单。主要工作是编制、发布绿色设计产品评价标准，编制绿色设计产品的申请材料，政府主管部门为：工信部、工信厅。
    </p>
    <h2>②流程</h2>
    <p class="content" style="text-align: center">
      <img style="width: 600px" src="../../images/绿色设计流程图.png" alt="">
    </p>

    <h2>③案例</h2>
    <div class="content" style="margin: 0 auto">
      <ul>
        <li ><img src="../../images/三一汽车制造有限公司.jpg" alt=""  style="width: 100px"></li>
        <li ><img src="../../images/湖南三友环保科技有限公司.png" alt="" ></li>
        <li ><img src="../../images/长沙矿冶研究院有限责任公司.png" alt="" ></li>
        <li ><img src="../../images/际华三五一七橡胶制品有限公司.png" alt="" ></li>
        <li ><img src="../../images/湖南长远锂科股份有限公司.png" alt="" ></li>
      </ul>
    </div>
    <h2 style="text-align: center">绿色设计产品评价综合服务</h2>
  </div>
</template>

<script>
import GreenDesignProducts from "@/View/module/GreenDesignProducts";
import GreenPark from "@/View/module/GreenPark";
import GreenFactory from "@/View/module/GreenFactory";
import GreenSupplyChain from "@/View/module/GreenSupplyChain";

export default {
  name: "GreenEvaluation",
  data(){
    return{
      GreenDesignProducts,
      GreenPark,
      GreenFactory,
      GreenSupplyChain,
      activeName:'first'
    }
  }
}
</script>

<style scoped>
#main {
  width: 1200px;
  margin: 110px auto 45px auto;
}

h2{
  margin-top: 40px;
}
.content{
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
}
ul{
  text-align: center;
}
ul li{
  display: inline-block;
  width: 400px;
  margin:0 20px 0 20px;
}
ul li img{
  width: 100%;
}
</style>